@font-face {
    font-family: "FONT";
    src:  url("https://cdn.glitch.com/a654e63b-496c-4135-a627-fb8e3b38591b%2FRusso_One.ttf?v=1561405270231");
}

@keyframes spin {
	0% {transform: rotate(0deg); }
	100% {transform: rotate(360deg); }
}

.loader {
    margin: auto;
	width: 25px;
	height: 25px;
  
    border: 0.15em solid rgba(15,15,25,1);
    border-right: 0.15em solid rgba(15,15,25,1);
    border-left: 0.15em solid rgba(15,15,25,1);
    border-radius: 8px;
  
    animation: spin 1s ease infinite;
}

* {
    box-sizing: border-box;
    font-weight: 100;
}

html, 
body {
    height: 100%;
    cursor: default;
}

body {
    font-family: "FONT";
    width:100%;
    margin:0;
    padding:0;
    color: white;
    background-color: #030d14;
}

.main-tab {
    max-width: 1000px;
    padding: 25px;
    margin: auto;
    margin-top: 20px;
}

.button {
    cursor: pointer;
    min-width: 140px;
    font-family: "FONT";
    color: rgba(15,15,25,1);
    height: 50px;
    padding: 8px;
    font-size: 20px;
    background-color: #ffaa00;
    border-radius: 8px;
    border: 2px solid #ffaa00;
}

.button.disabled {
    color: gray;
    cursor: not-allowed;
}

.button.small {
    min-width: 70px;
    height: 35px;
    padding: 5px;
    border-radius: 0;
}

.button:hover {
    color: #ffaa00;
    background-color: rgba(0,0,0,0);
}

.button:hover > .loader {
    border-color: #ffaa00;
}

.wrong {
    color: red!important;
}

.glowing {
    text-shadow: 0px 0px 15px #ffaa00;
}

.label-value-list {
    margin: 0;
    padding-left: 0;
}

.label-value-list > li {
    margin-bottom: 5px;
    list-style-type: none;
}

.label-value-list.small-text > li {
    margin-bottom: 0;
}

.label-value-list > li > span {
    margin-left: 5px;
}

.small-text {
    color: lightgray;
    font-size: 12px;
}

.highlight-text {
    color: #ffaa00;
}

.input-box {
    width: 150px;
    font-family: "FONT";
    font-size: 18px;
    color: white;
    padding: 5px;
    background-color: rgba(15,15,25,1);
    border: 2px solid #ffaa00;
}

.input-box:focus {
    outline: none;
    box-shadow: 0 0px 10px #ffaa00;
}

.web3modal-provider-name {
    font-weight: 100;
}

@media only screen and (max-width: 600px) {
    .main-tab { 
        padding: 0;
        padding-top: 25px; 
    }
}