#vesting {
    padding: 30px;
    width: 500px;
    margin: auto;
    box-shadow: 0 0 20px #fa0;
    border-radius: 8px;
}

#vesting > h2 {
    margin-top: 0px;    
    margin-bottom: 40px;
}

#vesting .info-box {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
}

#claim-button {
    text-align: center;
}

#claim-status {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
}

@media only screen and (max-width: 600px) {
    #vesting {
        width: 100%;
    }
}